<template>
    <div id="content" class="notes hide-scrollbar" ref="container" :class="{ 'with-trial': trialEndDate }">
        <div class="supervise-top-bar-actions">
            <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
        </div>

        <div class="supervise-container">
            <!-- SIDEBAR FILTERS -->
            <div v-if="location != ''" class="sidebar-filters" :class="{ 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <FilterNotes></FilterNotes>
            </div>
            <!-- NOTES -->
            <div class="notes-column">
                <div class="list-notes">
                    <div class="activity" v-if="location != ''">
                        <div class="text">{{ $t('notes.activity_in') }}</div>
                        <div class="select">
                            <v-select hide-details class="select location" v-model="location" :items="Object.values(locations)" item-text="name" item-value="id" dense :no-data-text="$t('supervise.filter.no_results')" @change="setLocation()"></v-select>
                        </div>
                    </div>
                    <div v-else class="empty">
                        {{ $t('notes.empty_location') }} <router-link class="button" :to="{ name: 'AddSite' }">{{ $t('notes.here') }}</router-link>
                    </div>
                    <template v-if="Object.values(notes).length > 0">
                        <div class="date" v-for="(group, date) in notes" :key="date">
                            <div class="separator">
                                <div class="caption">{{ date }}</div>
                            </div>
                            <note :isCentral="true" v-for="note in group" :note="note" :key="note.id"></note>
                        </div>
                    </template>
                    <template v-else-if="location != '' && Object.values(notes).length == 0">
                        <div class="empty">{{ $t('notes.empty_notes') }}</div>
                    </template>
                </div>
                <reply v-if="location != ''" :isCentral="true"></reply>
            </div>
        </div>
    </div>
</template>
<script>
import i18n from '@/i18n'
import note from '@/components/domain/logbook/note'
import reply from '@/components/domain/logbook/reply'
import FilterNotes from '@/components/domain/logbook/filter'

export default {
    name: 'Logbook',
    components: { note, reply, FilterNotes },
    data() {
        return {
            location: '',
            date: moment(),
            uniqueEdit: '',
            addNewNote: '',
            addNote: false,
            newNote: {
                id: 'new',
                image: '',
                message: '',
                created_date: '',
                employee: false,
                actions: {},
                comments: {}
            },
            allAlertsData: {},
            allActivityData: {},
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        moment: function () {
            return moment()
        },
        locations() {
            var loc = this.$store.getters['location/getListTable']()
            return loc
        },

        tools() {
            let tools = this.$store.getters['getTools']
            return tools ? Object.keys(tools) : []
        },

        notes() {
            var notes = this.$store.getters['notes/getAllLogs']
            var group = {}

            if (notes) {
                var sortedNotes = Object.values(notes).sort((a, b) => (a.created_date > b.created_date ? 1 : b.created_date > a.created_date ? -1 : 0))
                if (sortedNotes) {
                    for (var note in sortedNotes) {
                        var idx = sortedNotes[note]
                        if (idx) {
                            // TODO - Do not translate all dates
                            var date = i18n.t(formatRelative(idx.created_date, 'DD/MM/YYYY', true))
                            if (typeof group[date] == 'undefined') {
                                group[date] = []
                            }
                            group[date].push(idx)
                        }
                    }
                }
            }
            return group
        },

        emp_id() {
            return this.$store.getters['loginUser/getLocalUser']
        },
        currentEmployee() {
            if (this.emp_id) {
                var id = this.emp_id.employee_id
                var emp = this.$store.getters['employee/getEmployee'](id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },
        hasAccess() {
            if (this.currentEmployee) {
                return this.$store.getters['hasAccessUser'](this.currentEmployee.id, 0)
            } else if (this.isNew) {
                return this.isNew
            } else {
                return false
            }
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },
    methods: {
        selectDate(date) {
            let now = moment()
            this.date = moment(date).set({ h: now.get('h'), m: now.get('m') })
            this.load()
        },
        resetText() {
            this.addNewNote = ''
        },
        setLocation() {
            var self = this
            self.$overlay.loading()
            this.$store.commit('notes/setLocationFiltered', this.location)
            var filters = this.$store.getters['notes/getFiltersActive']
            var locationId = this.$store.getters['notes/getLocationFiltered']
            filters.location_id = locationId
            this.$store.dispatch('notes/loadLogs', filters).then(function (response) {
                self.$store.dispatch('notes/loadFilters', { locationId: locationId }).then(function () {
                    self.scrollToBottom()
                    self.$overlay.hide()
                })
            })
        },

        load() {
            var self = this
            self.$store.dispatch('location/loadListTable', {}).then(function () {
                self.$store.dispatch('employee/loadList', {})
                self.setLocation()
                self.selectLocation()
                self.$overlay.hide()
            })
        },
        refreshNotes(noScroll) {
            var self = this
            var locationId = this.$store.getters['notes/getLocationFiltered']
            var filters = this.$store.getters['notes/getFiltersActive']
            filters.location_id = locationId

            this.$store.dispatch('notes/loadLogs', filters).then(function (response) {
                if (!noScroll) {
                    self.scrollToBottom()
                }
            })
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },
        scrollToBottom() {
            var container = document.querySelector('.list-notes')
            container.scrollTop = container.scrollHeight
        },
        selectLocation() {
            if (this.location == '' && Object.keys(this.locations).length > 0) {
                this.location = Object.values(this.locations)[0].id
                this.setLocation()
            }
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('LogbookSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('LogbookSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    created() {
        this.load()
    },
    mounted() {
        var self = this
        self.selectLocation()
        this.$nextTick(() => {
            self.scrollToBottom()
        })

        this.setFiltersSidebarIsHiddenValue()
    },
    watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#fullcontent > #content.notes {
    $activity-height: 60px;
    $contenedor-height: 62px + 48px + 10px;
    overflow: hidden;

    .notes-column {
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-200, 1));
        @include border-radius($default-border-radius);
        background-color: $color-neutral-50;
        padding: 9px;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .list-notes {
        @extend .scrollbar;
        @extend .scrollbar-transparent;
        height: calc(100% - #{$activity-height} - #{$contenedor-height});
        overflow: auto;
        margin: $activity-height 0 0 0;

        // // Parent
        // scroll-snap-type: both mandatory;
        // scroll-padding: 60px 0;

        .date {
            // // Child
            // scroll-snap-align: end;
            // scroll-margin: 60px 0;
        }

        .separator {
            @include display-flex();
            @include justify-content();
            position: relative;
            text-align: center;

            .caption {
                @include font-size(xs);
                @include background($color: $color-secondary-500);
                @include border-radius(4px);
                padding: 6px;
                font-family: $text-bold;
                color: $color-neutral-200;
                z-index: 1;
                text-transform: uppercase;
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                border-top: 1px solid $color-neutral-300;
                background: $color-neutral-300;
                width: 100%;
                transform: translateY(-50%);
            }
        }
    }
    .activity {
        @include border-radius($default-border-radius);
        @include display-flex();
        background-color: $color-neutral-50;
        position: absolute;
        top: 0px;
        padding: 12px;
        z-index: $z-bot-content;
        width: 100%;
        height: $activity-height;
        left: 0px;

        .text {
            @include font-size(lg);
            font-family: $text-bold;
            color: $color-black;
            padding-right: 12px;
            padding-bottom: 10px;
        }
    }
    .contenedor {
        height: $contenedor-height;

        .post-as {
            top: unset;
            bottom: 0;
        }
    }

    &.with-trial {
        max-height: calc(100% - 60px - 55px) !important;
    }
}
</style>
