<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE text -->
            <div class="filter">
                <label>{{ $t('notes.search') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.text && Object.values(currentFilters.text).length > 0,
                        changed: currentFilters.text && currentFilters.text != activeFilters.text && Object.values(currentFilters.text).length > 0
                    }"
                    hide-details
                    class="input"
                    v-model="currentFilters.text"
                    type="text"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE employees -->
            <div class="filter">
                <label>{{ $t('notes.user') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.employees && Object.values(currentFilters.employees).length > 0,
                        changed: JSON.stringify(currentFilters.employees) != JSON.stringify(activeFilters.employees) && Object.values(currentFilters.employees).length > 0
                    }"
                    hide-details
                    class="select"
                    v-if="optionsUsers"
                    v-model="currentFilters.employees"
                    :items="Object.values(optionsUsers)"
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.employees" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE DATE -->
            <div class="filter date">
                <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.start_date,
                        changed: JSON.stringify(currentFilters.start_date) != JSON.stringify(activeFilters.start_date)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.start_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.start_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.start_date == 'custom'">
                    <!-- <v-text-field
						class="date start"
			            v-model="currentFilters.date.start_date"
			            placeholder="DD/MM/YYYY"
			            hide-details
			            @change="currentFilters.date.start_date = parseDate(currentFilters.date.start_date)"
		            ></v-text-field> -->
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />

                    <div class="separator"></div>

                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>

            <!-- TYPE DESTACADAS -->
            <div class="filter demora">
                <div
                    class="filter-ball"
                    :class="{
                        filtered: currentFilters.highlight && Object.values(currentFilters.highlight).length > 0,
                        changed: currentFilters.highlight && currentFilters.highlight != activeFilters.highlight
                    }"
                ></div>
                <label>{{ $t('notes.only_highlight') }}</label>
                <input class="switch-input" type="checkbox" v-model="currentFilters.highlight" />
                <div class="description">
                    {{ $t('notes.only_highlight_desc') }}
                </div>
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showResetButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    components: {
        Tags,
        FilterActions
    },
    name: 'FilterNotes',
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                employees: '',
                text: '',
                highlight: 0,
                start_date: 'noDate'
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        filters() {
            return this.$store.getters['notes/getFiltersActive']
        },
        optionsLocations() {
            var locations = this.$store.getters['location/getListTable']()
            return locations
        },
        optionsTemplates() {
            return this.$store.getters['notes/getFilterTemplate']()
        },
        optionsUsers() {
            return this.$store.getters['notes/getFilterLogbook'].employees
        },
        optionsDate() {
            return [
                { value: 'noDate', name: i18n.t('supervise.filter.noDate') },
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        showResetButton() {
            if (this.activeFilters.text != this.emptyFilters.text) {
                return true
            }
            if (this.activeFilters.employees != this.emptyFilters.employees) {
                return true
            }
            if (this.activeFilters.highlight != this.emptyFilters.highlight) {
                return true
            }
            if (this.activeFilters.start_date != this.emptyFilters.start_date) {
                return true
            }
            return false
        }
    },
    methods: {
        updateFilters() {
            this.$emit('applyFilters', this.allFilters)
        },
        calcDate() {
            this.currentFilters.date && this.currentFilters.date.start_date ? (this.maxDate = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')) : true
            this.currentFilters.date && this.currentFilters.date.end_date ? (this.minDate = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')) : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },

        applyFilters() {
            this.$overlay.loading()

            var definitiveFilters = {}

            if (this.currentFilters.text) {
                definitiveFilters.text = this.currentFilters.text
            } else definitiveFilters.text = ''

            // employees
            if (this.currentFilters.employees) {
                var filter = this.currentFilters.employees
                definitiveFilters.employees = []
                for (var index in filter) {
                    definitiveFilters.employees.push(filter[index].id)
                }
                definitiveFilters.employees = definitiveFilters.employees.toString()
            } else {
                definitiveFilters.employees = ''
            }

            if (this.currentFilters.start_date) {
                if (this.currentFilters.start_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.start_date
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            if (this.currentFilters.highlight) {
                definitiveFilters.highlight = true
            } else definitiveFilters.highlight = 0

            var self = this
            this.changesDetected = false
            var locId = this.$store.getters['notes/getLocationFiltered']
            this.$store.commit('notes/setFiltersActive', definitiveFilters)
            if (locId) {
                this.$store
                    .dispatch('notes/loadLogs', {
                        text: definitiveFilters.text,
                        location_id: locId,
                        start_date: definitiveFilters.start_date,
                        end_date: definitiveFilters.end_date,
                        highlight: definitiveFilters.highlight,
                        employees: definitiveFilters.employees
                    })
                    .then(function () {
                        // self.activeFilters = { ...self.currentFilters };
                        self.emptyFilters.location_id = locId
                        self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                        self.$overlay.hide()
                    })
            }
        },
        resetFilters() {
            var self = this
            var locId = this.$store.getters['notes/getLocationFiltered']
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('notes/setFiltersActive', this.currentFilters)

            this.changesDetected = false
            this.$store
                .dispatch('notes/loadLogs', {
                    location_id: locId,
                    start_date: 'noDate'
                })
                .then(function () {
                    self.$overlay.hide()
                })
        }
    },
    mounted() {
        this.activeFilters = {
            ...this.$store.getters['notes/getFiltersActive']
        }
        this.currentFilters = {
            ...this.$store.getters['notes/getFiltersActive']
        }
        this.applyFilters()
    },
    created() {},
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
                this.$forceUpdate()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
