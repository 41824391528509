var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('assets.filter.filter_data')))]),_c('div',{staticClass:"filter-items"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('notes.search')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.text),expression:"currentFilters.text"}],staticClass:"input",class:{
                        filtered: _vm.currentFilters.text && Object.values(_vm.currentFilters.text).length > 0,
                        changed: _vm.currentFilters.text && _vm.currentFilters.text != _vm.activeFilters.text && Object.values(_vm.currentFilters.text).length > 0
                    },attrs:{"hide-details":"","type":"text"},domProps:{"value":(_vm.currentFilters.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilters()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters, "text", $event.target.value)}}})]),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('notes.user')))]),(_vm.optionsUsers)?_c('v-autocomplete',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.employees && Object.values(_vm.currentFilters.employees).length > 0,
                        changed: JSON.stringify(_vm.currentFilters.employees) != JSON.stringify(_vm.activeFilters.employees) && Object.values(_vm.currentFilters.employees).length > 0
                    },attrs:{"hide-details":"","items":Object.values(_vm.optionsUsers),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                        "+_vm._s(index == 0 ? '' : '')+"\n                    ")]}}],null,false,2303138646),model:{value:(_vm.currentFilters.employees),callback:function ($$v) {_vm.$set(_vm.currentFilters, "employees", $$v)},expression:"currentFilters.employees"}}):_vm._e(),_c('Tags',{attrs:{"tags":_vm.currentFilters.employees,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                        filtered: _vm.currentFilters.start_date,
                        changed: JSON.stringify(_vm.currentFilters.start_date) != JSON.stringify(_vm.activeFilters.start_date)
                    },attrs:{"hide-details":"","items":_vm.optionsDate,"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},on:{"change":function($event){_vm.currentFilters.start_date == 'custom' ? (_vm.currentFilters.date = {}) : true}},model:{value:(_vm.currentFilters.start_date),callback:function ($$v) {_vm.$set(_vm.currentFilters, "start_date", $$v)},expression:"currentFilters.start_date"}}),(_vm.currentFilters.start_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.start_date),expression:"currentFilters.date.start_date"}],staticClass:"date-input start",attrs:{"max":_vm.minDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.start_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "start_date", $event.target.value)}}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.end_date),expression:"currentFilters.date.end_date"}],staticClass:"date-input end",attrs:{"min":_vm.maxDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.end_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "end_date", $event.target.value)}}})]):_vm._e()],1),_c('div',{staticClass:"filter demora"},[_c('div',{staticClass:"filter-ball",class:{
                        filtered: _vm.currentFilters.highlight && Object.values(_vm.currentFilters.highlight).length > 0,
                        changed: _vm.currentFilters.highlight && _vm.currentFilters.highlight != _vm.activeFilters.highlight
                    }}),_c('label',[_vm._v(_vm._s(_vm.$t('notes.only_highlight')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.highlight),expression:"currentFilters.highlight"}],staticClass:"switch-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.currentFilters.highlight)?_vm._i(_vm.currentFilters.highlight,null)>-1:(_vm.currentFilters.highlight)},on:{"change":function($event){var $$a=_vm.currentFilters.highlight,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.currentFilters, "highlight", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.currentFilters, "highlight", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.currentFilters, "highlight", $$c)}}}}),_c('div',{staticClass:"description"},[_vm._v("\n                    "+_vm._s(_vm.$t('notes.only_highlight_desc'))+"\n                ")])])]),_c('FilterActions',{attrs:{"showClearFiltersButton":_vm.showResetButton,"clearFiltersCallback":_vm.resetFilters,"filterCallback":_vm.applyFilters,"changesDetected":_vm.changesDetected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }